import React from "react";
import "./LoaderLogo.css";

class LoaderLogo extends React.Component {

  render() {
    return (
      <div className="loader">
        <div className="loader-text">Souvik Samanta</div>
      </div>
    );
  }
}

export default LoaderLogo;